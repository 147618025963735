import React from "react";
import DefaultLayout from "../components/DefaultLayout";
import Hero from "../components/bricks/Hero";
import MultiFeature from "../components/bricks/MultiFeature";
import FeatureOverview from "../components/FeatureOverview";
import {themeDark} from "../page-styles/landingpage.css";
import ThemedSingleFeature from "../components/bricks/ThemedSingleFeature";
import ThemeBox from "../components/bricks/ThemeBox";
import {ThemedButton} from "../components/xui/ThemedButton";

const Showcase = ({location}) => (
  <DefaultLayout
    title="Project Showcase"
    description="A dedicated project management tool for game developers? Here's the details!"
    location={location}
  >
    <Hero
      size="sm"
      title="Project Showcase"
      subline="Teams all over the world are using Codecks everyday to manage their projects. Here's some of them!"
    />
    <MultiFeature
      features={[
        {
          img: "showcase/unity-open-projects.jpg",
          lead: "Unity",
          title: "Unity Open Projects",
          description:
            "A collaborative initiative where Unity and the community come together to create open source projects and expose the game development journey.",
        },
        {
          img: "showcase/nivalis.jpg",
          lead: "ION LANDS / 505 Games",
          title: "Nivalis",
          description: "A cyberpunk slice-of-life sim from the team behind Cloudpunk.",
        },
      ]}
    />
    <MultiFeature
      features={[
        {
          img: "showcase/suzerain.jpg",
          lead: "Torpor Games / Fellow Traveller",
          title: "Suzerain",
          description:
            "Suzerain is a political drama set in the 1950s, allowing players to explore what they would do if they had to lead a country in political turmoil. Beware or embrace corruption; shirk or uphold ideals. How will you lead?",
        },
        {
          img: "showcase/curious-expedition-2.jpg",
          lead: "Maschinen-Mensch / Thunderful Publishing",
          title: "Curious Expedition 2",
          description:
            "A turn-based narrative roguelike set in a reimagined version of the late 19th century that uses procedural gameplay and story elements to create unique adventures every time you play.",
        },
      ]}
    />
    <MultiFeature
      features={[
        {
          img: "showcase/betweeen-horizons.jpg",
          lead: "DigiTales Interactive / Assemble Entertainment",
          title: "Between Horizons",
          description:
            "A branching sci-fi detective adventure set aboard a generation ship from the creators of Lacuna.",
        },
        {
          img: "showcase/finding-hannah.jpg",
          lead: "Fein Games",
          title: "Finding Hannah",
          description:
            "Finding Hannah combines beautiful hidden object scenes with compelling merge gameplay to tell a story about happiness, love, and family.",
        },
      ]}
    />
    <MultiFeature
      features={[
        {
          img: "showcase/tentacular.jpg",
          lead: "Firepunchd / Devolver Digital",
          title: "Tentacular",
          description:
            "Tentacular is a VR game about a gigantic but good-hearted tentacled beast trying to figure out its place in the world.",
        },
        {
          img: "showcase/out-there.jpg",
          lead: "MiClos / Modern Wolf",
          title: "Out There: Oceans of Time",
          description:
            "The sequel to Out There, the award-winning space exploration game blending roguelike, resource management and interactive fiction.",
        },
      ]}
    />
    <MultiFeature
      features={[
        {
          img: "showcase/westaybehind.jpg",
          lead: "Backwoods Entertainment / Application Systems Heidelberg",
          title: "We Stay Behind",
          description:
            "A surreal tale of mystery where dreams and reality intersect set in a beautiful, stylized 3D environment.",
        },
        {
          img: "showcase/industria.jpg",
          lead: "Bleakmill / Headup",
          title: "INDUSTRIA",
          description:
            "A first-person shooter that takes you from East Berlin into a parallel reality, shortly before the end of the Cold War.",
        },
      ]}
    />
    <MultiFeature
      features={[
        {
          img: "showcase/lonely-mountains.jpg",
          lead: "Megagon Industries / Thunderful Publishing",
          title: "Lonely Mountains: Downhill",
          description:
            'Simply the best mountain biking game on the market, nominated in the "Excellence in Design" category (plus two Honorable Mentions) at the IGF 2020.',
        },
        {
          img: "showcase/fermi-paradox.jpg",
          lead: "Anomaly Games / Wings",
          title: "The Fermi Paradox",
          description:
            "A choice-driven sci-fi narrative strategy game in which your decisions in one corner of the galaxy can upend entire civilizations in another.",
        },
      ]}
    />
    <MultiFeature
      features={[
        {
          img: "showcase/krakenacademy.jpg",
          lead: "Happy Broccoli Games / Fellow Traveller",
          title: "Kraken Academy!!",
          description:
            "Join forces with a magical kraken to manipulate a time loop and save the world in this hilarious adventure game.",
        },
        {
          img: "showcase/hellpie.jpg",
          lead: "Sluggerfly / Headup",
          title: "Hell Pie",
          description: "This obscene 3D platformer takes bad taste to the next level!",
        },
      ]}
    />
    <MultiFeature
      features={[
        {
          img: "showcase/through-the-darkest-of-times.jpg",
          lead: "Paintbucket Games / HandyGames (THQ Nordic)",
          title: "Through the Darkest of Times",
          description:
            "An award-winning historical resistance strategy game, focusing on conveying the somber mood of the period and the very real struggles of average people living in the 3rd Reich.",
        },
        {
          img: "showcase/good-company.jpg",
          lead: "Chasing Carrots / The Irregular Corporation",
          title: "Good Company",
          description:
            "Good Company is a tycoon management simulation about building, automating and optimizing a robot manufacturing empire, where you are the CEO.",
        },
      ]}
    />
    <MultiFeature
      features={[
        {
          img: "showcase/neo-cab.jpg",
          lead: "Chance Agency / Fellow Traveller",
          title: "Neo Cab",
          description:
            "Neo Cab is an emotional survival game about gig labor, tech disruption and the experience of being a driver-for-hire. ",
        },
        {
          img: "showcase/tunche.jpg",
          lead: "LEAP Game Studios / HypeTrain Digital",
          title: "Tunche",
          description:
            "Tunche is a charming, hand-drawn beat'em up hack and slash game with roguelike elements, co-op, and a pinch of shaman witchcraft.",
        },
      ]}
    />
    <ThemeBox theme={themeDark}>
      <ThemedSingleFeature
        title="Check our Community Hub"
        img="community/community-showcase.jpg"
        description={<>This page features community-made resources like integrations and videos.</>}
      >
        <ThemedButton to="/community/">Open Community Hub</ThemedButton>
      </ThemedSingleFeature>
    </ThemeBox>
    <FeatureOverview />
  </DefaultLayout>
);

export default Showcase;
